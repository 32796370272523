export default {
  computed: {

    prealertes() {
      return this.$store.state.mainAppStore.prealertes
    },

  },
  async created() {
    try {
      const response = await this.$store.dispatch('mainAppStore/fetchPrealertesListItems')
      
    } catch (err) {
      console.error(err.response.headers)
    }
    // this.$store.dispatch('mainAppStore/fetchPrealertesListItems').then(response => {
    // })
    //   .catch(err => {
    //     console.error(err.response.headers)
    //   })
  },
}
