<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card-code title="Liste des Prealertes">
          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="prealertcolumns"
            :rows="prealertes"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="detailPrealert(props.row)">
                      <feather-icon
                        icon="Edit2Icon"
                        class="mr-50"
                      />
                      <span>Détail</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <span v-else-if="props.column.field === 'code'">
                <b-badge :variant="statusVariant(props.row.code)">
                  {{ props.row.code }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'createdAt'">
                {{ getDate(props.row.createdAt) }}
              </span>
              <span v-else-if="props.column.field === 'createdBy'">
                <p>{{ `${props.row.createdBy.lastName}  ${props.row.createdBy.firstName} ` }}</p>
                <!-- {{ getDate(props.row.createdBy) }} -->
              </span>
              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','20','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card-code>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormSelect, BRow, BCol, BPagination, BFormGroup, BFormInput, BDropdown, BDropdownItem, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'

import moment from 'moment'
import prealertMixin from '../@core/mixins/prealertMixin'

export default {
  components: {
    BCardCode,
    VueGoodTable,

    BFormSelect,
    BRow,
    BCol,
    BPagination,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BBadge,
  },
  directives: {
    Ripple,
  },
  mixins: [prealertMixin],
  data() {
    return {
      required,
      pageLength: 10,
      dir: false,
      prealertcolumns: [
        {
          label: 'Date',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par date',
          },
        },
        {
          label: 'Balise',
          field: 'balise.hameau',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par balise',
          },
        },
        {
          label: 'Village',
          field: 'balise.zone.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par balise',
          },
        },
        {
          label: 'Arrondissement',
          field: 'balise.zone.arrondissement.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par auther',
          },
        },
        {
          label: 'Commune',
          field: 'balise.zone.arrondissement.commune.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par auther',
          },
        },
        {
          label: 'Departement',
          field: 'balise.zone.arrondissement.commune.departement.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par auther',
          },
        },
        {
          label: 'Auteur Pré-alerte',
          field: 'createdBy',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par auther',
          },
        },
        {
          label: 'Code couleur',
          field: 'code',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par code',
          },
        },
        {
          label: 'Type Commentaire',
          field: 'commentType',
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche Par code',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      balise: {
        name: null,
        longitude: null,
        latitude: null,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        JAUNE      : 'info',
        VERT : 'success',
        ROUGE     : 'danger',
        ORANGE     : 'warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
  },
  methods: {
    getDate(date) {
      // moment.locale()
      moment.locale('fr')
      return moment(date).format('LLL')
      // return moment(date).format('DD-MM-YYYY HH:mm')
    },
    detailPrealert(prealert) {
      console.log('ici')
      // eslint-disable-next-line no-undef
      this.$router.push(`detailprealert/${prealert.id}`)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
